import Button from 'components/Button';
import { ButtonProps } from 'components/Button/Button';
import { ChevronDown } from 'lucide-react';
import * as React from 'react';
import clsx from 'clsx';
import {
  DropdownMenu as RDropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './@radix-styled';

type Option = Record<string, any>;

interface Props<T> {
  options: Option[];
  className?: string;
  btnProps?: ButtonProps;
  children?: React.ReactNode;
  onChange?: (val: T) => void;
  renderItem: (val: Option) => React.ReactNode;
  keyExtractor: (val: Option) => T;
  ariaLabel: string;
  noIcon?: boolean;
  Icon?: React.ReactNode;
  align?: 'end' | 'start' | 'center';
  contentClassName?: string;
  dropdownItemClassName?: string;
}

function DropdownMenu<T extends string | number>(props: Props<T>) {
  const {
    options,
    btnProps,
    onChange,
    ariaLabel,
    renderItem,
    keyExtractor,
    children,
    noIcon,
    align = 'center',
    Icon,
    contentClassName,
    dropdownItemClassName
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleChange = (value: T) => () => {
    setOpen(false);
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  return (
    <RDropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          className="relative"
          size="badge"
          aria-label={ariaLabel}
          variant="ghost"
          {...btnProps}
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            {children}
            {!noIcon && Icon && Icon}
            {!noIcon && !Icon && (
              <ChevronDown
                size={16}
                className={clsx(' transition-transform', open && 'rotate-180')}
              />
            )}
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={contentClassName}
        align={align}
        collisionPadding={{ top: 10, left: 10, right: 10 }}
      >
        {options.map(option => (
          <DropdownMenuItem
            className={clsx('!px-2', dropdownItemClassName)}
            key={keyExtractor(option)}
            onClick={handleChange(keyExtractor(option))}
          >
            {renderItem(option)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </RDropdownMenu>
  );
}

DropdownMenu.displayName = 'DropdownMenu';
export default DropdownMenu;
